<script setup lang="ts">
import { ErrorIcon } from "../../components/index";
import { event as gaEvent } from "vue-gtag";
import { onMounted } from "vue";

onMounted(() => {
  gaEvent("page_view", {
    page_title: "Error",
    hostname: window.location.hostname,
  });
});
</script>

<template>
  <div class="h-full">
    <div class="m-auto max-w-xl p-4 md:p-10">
      <div class="">
        <div class="rounded-2xl bg-grey-300 px-3 py-4 md:p-6">
          <div class="mb-4 flex items-center">
            <div class="m-auto drop-shadow-xl">
              <ErrorIcon />
            </div>
          </div>

          <div class="mb-2 text-center text-lg font-semibold md:text-2xl">
            There was an issue with your link
          </div>
          <div class="text-center text-sm md:text-base">
            Please try again shortly. If the issue persists, contact Runa
            support at ordersupport@runa.io
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>
